<script setup lang="ts">
// COMPOSABLES
const { tt } = useTypedI18n();
useHead({
    script: [
        // Load the script
        { src: 'https://static.elfsight.com/platform/platform.js' },
    ],
});
</script>

<template>
    <BaseWidthContainer>
        <div class="bg-primary-100 flex flex-col gap-8 rounded-3xl p-6 md:gap-9 md:p-8 xl:flex-row xl:gap-12 xl:p-12">
            <div class="flex flex-col gap-y-8 md:gap-y-9 xl:gap-y-12">
                <div class="flex flex-col gap-y-4 md:gap-y-6">
                    <h4 class="text-4xl font-semibold md:text-5xl">
                        <i18n-t scope="global" tag="span" keypath="block.whoWeAre.title.base">
                            <span class="text-primary">{{ tt('block.whoWeAre.title.highlight') }}</span>
                        </i18n-t>
                    </h4>
                    <p class="text-base font-normal text-gray-700 md:text-lg xl:text-base">{{ tt('block.whoWeAre.description') }}</p>
                </div>
                <!-- Elfsight Google Reviews | Fleequid homepage - Google Reviews -->
                <div class="elfsight-app-6bbf9a72-3d6f-452e-a485-40b18000b46b" data-elfsight-app-lazy></div>
            </div>
            <div class="overflow-hidden">
                <img alt="Basco" src="/block/basco.png" class="aspect-[4/3] rounded-2xl object-cover xl:aspect-[3/4]" />
            </div>
        </div>
    </BaseWidthContainer>
</template>
<style>
.es-embed-root .es-widget-title-container,
.es-embed-root .es-header-write-review-button-container {
    display: none;
}
.es-embed-root .es-header-container {
    align-items: flex-start;
}
</style>
