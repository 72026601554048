<script setup lang="ts">
import type { BlockTwoColItem } from '~/components/Block/BlockTwoCol.vue';

// COMPOSABLES
const { tt } = useTypedI18n();

// DATA
const items = computed<BlockTwoColItem[]>(() => [
    {
        icon: 'fl:purchase',
        title: '300',
        description: tt('homepage.stats.section.purchase'),
    },
    {
        icon: 'fl:markets',
        title: '20+',
        description: tt('homepage.stats.section.markets'),
    },
    {
        icon: 'fl:team',
        title: '50+',
        description: tt('homepage.stats.section.team'),
    },
    {
        icon: 'fl:registrations',
        title: '1000+',
        description: tt('homepage.stats.section.registrations'),
    },
]);
</script>

<template>
    <BlockTwoCol :title="tt('homepage.stats.title')" :items="items" />
</template>
