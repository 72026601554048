<script setup lang="ts">
import lodashOmit from 'lodash-es/omit';

import { Language } from '~/apiClient';

const { tt, locale } = useTypedI18n();

// DATA
const items = [
    {
        id: 'register',
        label: tt('homepage.howItWorks.section.register.title'),
        content: tt('homepage.howItWorks.section.register.content'),
        defaultOpen: true,
    },
    {
        id: 'choose',
        label: tt('homepage.howItWorks.section.choose.title'),
        content: tt('homepage.howItWorks.section.choose.content'),
    },
    {
        id: 'bid',
        label: tt('homepage.howItWorks.section.bid.title'),
        content: tt('homepage.howItWorks.section.bid.content'),
    },
    {
        id: 'autobid',
        label: tt('homepage.howItWorks.section.autobid.title'),
        content: tt('homepage.howItWorks.section.autobid.content'),
    },
    {
        id: 'closure',
        label: tt('homepage.howItWorks.section.closure.title'),
        content: tt('homepage.howItWorks.section.closure.content'),
    },
];
const openedIndex = ref(0);
const picture = computed(() => ({
    img: {
        src: `/hp/how-it-works/${items[openedIndex.value].id}-${locale.value}.svg`,
        alt: items[openedIndex.value].label,
    },
    source: `/hp/how-it-works/${items[openedIndex.value].id}-tablet-${locale.value}.svg`,
}));
const accordionItems = computed(() => items.map(i => lodashOmit(i, ['images'])));

// HANDLERS
const onOpen = (index: number): void => {
    openedIndex.value = index;
};
</script>

<template>
    <BaseWidthContainer>
        <div
            class="bg-primary-50 flex flex-col gap-8 rounded-3xl px-4 py-6 md:gap-9 md:px-6 md:py-8 xl:grid xl:grid-cols-2 xl:gap-x-6 xl:gap-y-10 xl:px-12 xl:py-12"
        >
            <div class="flex flex-col gap-8 md:gap-9 xl:gap-10">
                <h4 class="text-4xl font-semibold md:text-5xl xl:row-auto">{{ tt('homepage.howItWorks.title') }}</h4>
                <div class="flex flex-col gap-6 md:gap-7 xl:gap-8">
                    <div class="xl:hidden">
                        <picture>
                            <source media="(min-width: 768px) and (max-width: 1279px)" :srcset="picture.source" />
                            <img v-bind="picture.img" class="w-full rounded-2xl object-cover" />
                        </picture>
                    </div>
                    <BaseAccordion size="xl" :items="accordionItems" @open="onOpen" />
                </div>
            </div>
            <div class="hidden xl:block">
                <picture>
                    <source media="(min-width: 768px) and (max-width: 1279px)" :srcset="picture.source" />
                    <img v-bind="picture.img" class="w-full rounded-2xl object-cover" />
                </picture>
            </div>
        </div>
    </BaseWidthContainer>
</template>
