<script setup lang="ts">
// COMPOSABLES
import type { BlockThreeColItem } from '~/components/Block/BlockThreeCol.vue';

const { tt } = useTypedI18n();

// DATA
const items = computed<BlockThreeColItem[]>(() => [
    {
        icon: 'i-fl-purchase',
        description: tt('homepage.why.section.purchase'),
    },
    {
        icon: 'i-fl-assistance',
        description: tt('homepage.why.section.assistance'),
    },
    {
        icon: 'i-fl-fairness',
        description: tt('homepage.why.section.fairness'),
    },
]);
</script>

<template>
    <BlockThreeCol :title="tt('homepage.why.title')" :items="items" />
</template>
