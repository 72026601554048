<script setup lang="ts">
import { AssetType, BusClass } from '~/apiClient';

// COMPOSABLES
const { tt } = useTypedI18n();
const { formatAlpRoute } = useAlpRouteUtils();
const localePath = useLocalePath();

// DATA
const items = [
    {
        to: localePath(formatAlpRoute({ assetType: AssetType.Bus, assetClass: BusClass.ClassOne })),
        label: tt(`asset.class.${BusClass.ClassOne}`),
        icon: 'i-fl-bus-class-one',
    },
    {
        to: localePath(formatAlpRoute({ assetType: AssetType.Bus, assetClass: BusClass.ClassTwo })),
        label: tt(`asset.class.${BusClass.ClassTwo}`),
        icon: 'i-fl-bus-class-two',
    },
    {
        to: localePath(formatAlpRoute({ assetType: AssetType.Bus, assetClass: BusClass.ClassThree })),
        label: tt(`asset.class.${BusClass.ClassThree}`),
        icon: 'i-fl-bus-class-three',
    },
    {
        to: localePath(formatAlpRoute({ assetType: AssetType.Bus, assetClass: BusClass.SchoolBus })),
        label: tt(`asset.class.${BusClass.SchoolBus}`),
        icon: 'i-fl-bus-class-schoolbus',
    },
];
</script>

<template>
    <BlockHero :subtitle="tt('homepage.hero.subtitle')" :items="items">
        <template #cover>
            <div
                class="relative left-1/2 top-1.5 mx-auto flex -translate-x-1/2 flex-nowrap justify-center gap-x-20 overflow-hidden sm:top-7 md:gap-x-44 xl:top-11 xl:gap-x-64"
            >
                <img
                    alt="Fleequid Bus"
                    src="/hp/yellow-bus.png"
                    class="h-[175px] w-[663px] object-cover object-right md:h-[341px] md:w-[1292px] xl:h-[438px] xl:w-[1660px]"
                />
                <img
                    alt="Fleequid Bus"
                    src="/hp/blue-bus.png"
                    class="h-[175px] w-[663px] object-cover object-left md:h-[341px] md:w-[1292px] xl:h-[438px] xl:w-[1660px]"
                />
            </div>
        </template>
        <template #title>
            <i18n-t scope="global" tag="span" keypath="homepage.hero.title.base">
                <span class="text-primary-100">{{ tt('homepage.hero.title.highlight') }}</span>
            </i18n-t>
        </template>
    </BlockHero>
</template>
