<script setup lang="ts">
// COMPOSABLES
const { tt } = useTypedI18n();

// DATA
const items = [
    {
        title: tt('block.support.section.business.title'),
        to: 'mailto:info@fleequid.com',
        label: 'info@fleequid.com',
    },
    {
        title: tt('block.support.section.press.title'),
        to: 'mailto:press@fleequid.com',
        label: 'press@fleequid.com',
    },
    {
        title: tt('block.support.section.careers.title'),
        to: 'https://www.linkedin.com/company/fleequid/',
        label: tt('block.support.section.careers.label'),
    },
];
</script>

<template>
    <BaseWidthContainer class="flex flex-col gap-y-6 md:gap-y-9 xl:gap-y-12">
        <h4 class="text-left text-4xl font-semibold md:text-5xl">
            {{ tt('block.support.title') }}
        </h4>
        <div class="flex flex-col items-stretch gap-4 md:grid md:grid-cols-2 md:gap-6 xl:grid-cols-3 xl:gap-12">
            <div v-for="(item, index) in items" :key="index" class="flex flex-col gap-1 md:gap-2 xl:gap-3">
                <h6 class="text-lg font-medium md:text-xl xl:text-2xl">{{ item.title }}</h6>
                <NuxtLink class="text-primary text-lg font-medium underline md:text-xl xl:text-2xl" :to="item.to">
                    {{ item.label }}
                </NuxtLink>
            </div>
        </div>
    </BaseWidthContainer>
</template>
